import React, { useState, useEffect } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import axios from "axios";
import UIkit from "uikit";

const Usage = (props) => {
  const [usages, setUsages] = useState([]);
  const [target, setTarget] = useState();
  const [targetDetails, setTargetDetails] = useState();

  useEffect(() => {
    async function initialLoad() {
      const result = JSON.parse(localStorage.getItem("user"));
      if (result) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/transactions/list`,
          {
            headers: {
              Authorization: `Bearer ${result.jwt}`,
            },
          }
        );

        let usages = [];
        for (let i = 0; i < data.length; i++) {
          usages.push({
            index: i + 1,
            endpointMethod: data[i].endpoint.method,
            endpointName: data[i].endpoint.name,
            tokensUsed: data[i].tokenCost,
            request: {
              headers: data[i].request.headers,
              data: data[i].request.data,
            },
            response: data[i].response.data,
            createdAt: data[i].createdAt,
          });
        }
        setUsages(usages);
      } else props.history.push("/sign-in");
    }

    initialLoad();
  }, []);

  const openDetailsModal = (target, details) => {
    setTarget(target);
    setTargetDetails(details);
    UIkit.modal("#usage-details").show();
  };

  const columns = [
    { name: "No.", selector: "index", sortable: true, width: "50px" },
    {
      name: "Endpoint",
      sortable: true,
      selector: "endpointName",
      cell: (row) => {
        return (
          <>
            <span
              className={`uk-label uk-label-${row.endpointMethod.toLowerCase()} uk-margin-small-right`}
            >
              {row.endpointMethod}
            </span>
            <span className="endpoint-selection-name uk-margin-small-right">
              {row.endpointName}
            </span>
          </>
        );
      },
    },
    {
      name: "Request",
      cell: (row) => (
        <button
          className="uk-button uk-button-primary uk-button-small"
          onClick={() => openDetailsModal("request", row.request)}
        >
          View Details
        </button>
      ),
    },
    {
      name: "Response",
      cell: (row) => (
        <button
          className="uk-button uk-button-primary uk-button-small"
          onClick={() => openDetailsModal("response", row.response)}
        >
          View Details
        </button>
      ),
    },
    {
      name: "Token(s) Used",
      selector: "tokensUsed",
      sortable: true,
      width: "150px",
    },
    {
      name: "Date of Usage",
      selector: "createdAt",
      sortable: true,
      cell: (row) => <Moment format="DD/MM/YYYY HH:mm">{row.createdAt}</Moment>,
    },
  ];

  return (
    <section className="uk-section uk-section-small uk-section-default uk-padding-remove-bottom">
      <div className="uk-container uk-margin-top">
        <h3>Token Usage</h3>
        <div className="uk-card uk-card-pricing">
          <div className="uk-card-body uk-padding-small">
            <DataTable
              columns={columns}
              data={usages}
              noHeader={true}
              highlightOnHover={true}
              striped={true}
              pagination={true}
            />
          </div>
        </div>
      </div>
      <div id="usage-details" className="uk-modal-container" data-uk-modal>
        {targetDetails && (
          <div className="uk-modal-dialog uk-modal-body">
            <h2 className="uk-modal-title uk-text-capitalize">
              {target} Details
            </h2>
            <SyntaxHighlighter
              language="json"
              style={docco}
              wrapLines={true}
              customStyle={{ padding: 20 }}
            >
              {JSON.stringify(targetDetails, 0, 2)}
            </SyntaxHighlighter>
          </div>
        )}
      </div>
    </section>
  );
};

export default Usage;
