import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

const Receipt = (props) => {
  const {
    params: { orderId },
  } = props.match;

  const [receipt, setReceipt] = useState();

  useEffect(() => {
    async function initialLoad() {
      const result = JSON.parse(localStorage.getItem("user"));
      if (result) {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/purchases/${orderId}`,
            {
              headers: {
                Authorization: `Bearer ${result.jwt}`,
              },
            }
          );
          if (data.completed) setReceipt(data);
          else props.history.push("/");
        } catch (err) {
          console.log(err.response);
          Swal.fire({
            icon: "error",
            title: err.response.data.error,
            text: err.response.data.message,
          }).then(() => {
            props.history.push("/history");
          });
        }
      } else props.history.push("/sign-in");
    }

    initialLoad();
  }, []);

  return (
    <section className="uk-section uk-section-small uk-section-default uk-padding-remove-bottom">
      <div className="uk-container uk-margin-top payments-card">
        <div className="uk-flex uk-margin-bottom">
          <Link
            className="uk-icon-button uk-button-primary uk-margin-right"
            uk-icon="icon: fa-solid-arrow-left; ratio: 0.8"
            to="/history"
          ></Link>
          <h3 className="uk-margin-remove">Receipt</h3>
        </div>
        <div className="uk-card uk-card-pricing">
          <div className="uk-card-body uk-padding">
            {receipt && (
              <>
                <div className="order-information uk-margin-medium-bottom">
                  <h3 className="order-header">Order Information</h3>
                  <dl className="uk-description-list">
                    {/* <div className="uk-flex uk-margin-top">
                    <div className="uk-width-1-2">
                      <dt className="uk-margin-small-bottom">Package</dt>
                      <dd className="uk-flex uk-flex-middle">
                        {receipt.package.name}
                      </dd>
                    </div>
                    <div className="uk-width-1-2">
                      <dt className="uk-margin-small-bottom">Tokens</dt>
                      <dd className="uk-flex uk-flex-middle">
                        {receipt.package.tokens}
                      </dd>
                    </div>
                  </div> */}
                    <div className="uk-flex uk-margin-top">
                      <div className="uk-width-1-3">
                        <dt className="uk-margin-small-bottom">Order ID</dt>
                        <dd className="uk-flex uk-flex-middle">
                          {receipt.orderIdPrefix}
                        </dd>
                      </div>
                      <div className="uk-width-1-3">
                        <dt className="uk-margin-small-bottom">
                          Date of Purchase
                        </dt>
                        <dd className="uk-flex uk-flex-middle">
                          {moment(receipt.createdAt).format("DD MMM YYYY")}
                        </dd>
                      </div>
                      <div className="uk-width-1-3">
                        <dt className="uk-margin-small-bottom">
                          Time of Purchase
                        </dt>
                        <dd className="uk-flex uk-flex-middle">
                          {moment(receipt.createdAt).format("HH:mm")}
                        </dd>
                      </div>
                    </div>
                  </dl>
                </div>
                <div className="order-details">
                  <h3 className="order-header">Order Details</h3>
                  <table className="uk-table uk-table-divider">
                    <thead>
                      <tr className="striped">
                        <th>Package</th>
                        <th>Tokens</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{receipt.package.name}</td>
                        <td>{receipt.package.tokens.toLocaleString()}</td>
                        <td>${receipt.package.price.toFixed(2)}</td>
                      </tr>
                      <tr className="striped">
                        <td colSpan="2" className="uk-text-bold">
                          Total
                        </td>
                        <td className="uk-text-bold">
                          ${receipt.package.price.toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Receipt;
