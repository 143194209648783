import gql from "graphql-tag";

const SUBSCRIPTION_QUERY = gql`
  query Subscription($user: ID!) {
    subscriptions(where: { user: $user }) {
      snapi_key
      tokens
    }
  }
`;

export default SUBSCRIPTION_QUERY;
