import gql from "graphql-tag";

const PACKAGES_QUERY = gql`
  {
    packages {
      id
      name
      tokens
      price
    }
  }
`;

export default PACKAGES_QUERY;
