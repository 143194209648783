import gql from "graphql-tag";

const API_LIST_QUERY = gql`
  {
    services {
      name
      description
      serviceId
      tags
      averageLatency
      category {
        name
      }
      provider {
        name
      }
    }
  }
`;

export default API_LIST_QUERY;
