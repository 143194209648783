import Login from "./Login";
import Register from "./Register";
import Home from "./Home";
import Documentation from "./Documentation";
import Pricing from "./Pricing";
import Receipt from "./Receipt";
import History from "./History";
import Usage from "./Usage";

const routes = [
  {
    path: "/",
    component: Home,
    name: "Home",
    exact: true,
    hasNav: true,
  },
  {
    path: "/documentation/:serviceId",
    component: Documentation,
    name: "Documentation",
    exact: true,
    hasNav: true,
  },
  {
    path: "/pricing",
    component: Pricing,
    name: "Pricing",
    exact: true,
    hasNav: true,
  },
  {
    path: "/receipt/:orderId",
    component: Receipt,
    name: "Receipt",
    exact: true,
    hasNav: true,
  },
  {
    path: "/history",
    component: History,
    name: "History",
    exact: true,
    hasNav: true,
  },
  {
    path: "/usage",
    component: Usage,
    name: "Usage",
    exact: true,
    hasNav: true,
  },
  {
    path: "/sign-in",
    component: Login,
    name: "Login",
    exact: true,
    hasNav: false,
  },
  {
    path: "/sign-up",
    component: Register,
    name: "Register",
    exact: true,
    hasNav: false,
  },
];

export default routes;
