import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import axios from "axios";

const History = (props) => {
  const [purchases, setPurchases] = useState([]);

  useEffect(() => {
    async function initialLoad() {
      const result = JSON.parse(localStorage.getItem("user"));
      if (result) {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/purchases/completed`,
          { user: result.user.id },
          {
            headers: {
              Authorization: `Bearer ${result.jwt}`,
            },
          }
        );

        let history = [];
        for (let i = 0; i < data.length; i++) {
          history.push({
            index: i + 1,
            id: data[i].id,
            name: data[i].package.name,
            tokens: data[i].package.tokens,
            price: data[i].package.price,
            createdAt: data[i].createdAt,
          });
        }
        setPurchases(history);
      } else props.history.push("/sign-in");
    }

    initialLoad();
  }, []);

  const columns = [
    { name: "No.", selector: "index", sortable: true, width: "50px" },
    { name: "Package", selector: "name", sortable: true },
    {
      name: "Tokens",
      selector: "tokens",
      sortable: true,
      cell: (row) => {
        return `${row.tokens.toLocaleString()}`;
      },
    },
    {
      name: "Price",
      selector: "price",
      sortable: true,
      cell: (row) => {
        return `$${row.price.toFixed(2)}`;
      },
    },
    {
      name: "Date of Purchase",
      selector: "createdAt",
      sortable: true,
      cell: (row) => <Moment format="DD/MM/YYYY HH:mm">{row.createdAt}</Moment>,
    },
    {
      name: "Receipt",
      cell: (row) => (
        <Link
          className="uk-button uk-button-primary uk-button-small"
          to={`/receipt/${row.id}`}
        >
          View Receipt
        </Link>
      ),
    },
  ];

  return (
    <section className="uk-section uk-section-small uk-section-default uk-padding-remove-bottom">
      <div className="uk-container uk-margin-top">
        <h3>Purchase History</h3>
        <div className="uk-card uk-card-pricing">
          <div className="uk-card-body uk-padding-small">
            <DataTable
              columns={columns}
              data={purchases}
              noHeader={true}
              highlightOnHover={true}
              striped={true}
              pagination={true}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default History;
