import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Switch, Route } from "react-router-dom";
import Nav from "../../components/Nav";

import routes from "../routes";

function App() {
  return (
    <div className="App">
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            component={(props) => (
              <>
                <Nav hasNav={route.hasNav} />
                <route.component {...props} />
              </>
            )}
            exact={route.exact}
          />
        ))}
      </Switch>
      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
}

export default App;
