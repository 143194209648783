import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/react-hooks";
import { withRouter, Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import Swal from "sweetalert2";

import REGISTER_MUTATION from "../../mutations/auth/register";

const RegisterSchema = yup.object().shape({
  username: yup.string().required("Username is required"),
  email: yup.string().required("Email is required").email("Invalid Email"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must contain at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/,
      "Password must contain at least 1 uppercase, lowercase character and number"
    ),
  cpassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Password does not match!"),
});

function Register(props) {
  const { handleSubmit, register, errors, reset } = useForm({
    mode: "onBlur",
    resolver: yupResolver(RegisterSchema),
  });

  const [signUp] = useMutation(REGISTER_MUTATION, {
    onCompleted: (data) => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Please verify your email!",
        onClose: () => {
          props.history.push("/sign-in");
        },
      });
    },
    onError: ({ graphQLErrors }) => {
      const {
        extensions: {
          exception: {
            data: { message },
          },
        },
      } = graphQLErrors[0];

      let alertPayload = {
        icon: "error",
        title: "Unable to sign up",
      };

      if (message[0] && message[0].messages[0]) {
        alertPayload.text = message[0].messages[0].message;
      }

      Swal.fire(alertPayload);
    },
  });

  const onSubmit = (values) => {
    const { username, email, password, cpassword } = values;

    if (password === cpassword) {
      Swal.fire({
        title: "Signing Up...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
          reset();
        },
        onOpen: () => {
          signUp({ variables: { input: { username, email, password } } });
        },
      });
    }
  };

  return (
    <>
      <div>
        <div className="uk-position-medium uk-position-left">
          <Link
            className="uk-icon-button uk-button-primary"
            uk-icon="icon: fa-solid-home; ratio: 0.8"
            to="/"
          ></Link>
        </div>
        <div className="uk-position-medium uk-position-right">
          <Link className="uk-button uk-button-primary" to="/sign-in">
            Sign In
          </Link>
        </div>
      </div>
      <div
        className="login-bg uk-width-1-1 uk-flex uk-flex-center uk-flex-middle"
        uk-height-viewport="expand:true"
      >
        <div className="uk-width-medium uk-padding-small">
          <div className="uk-flex uk-flex-middle uk-flex-center uk-margin-bottom auth-header">
            <img className="uk-margin-small-right" src="/logo.png" alt="" />
            <h3 className="uk-text-center uk-margin-remove">SNAPI</h3>
          </div>
          <form className="toggle-className" onSubmit={handleSubmit(onSubmit)}>
            <fieldset className="uk-fieldset">
              <div className="uk-margin-small">
                <div className="uk-inline uk-width-1-1">
                  <span
                    className="uk-form-icon uk-form-icon-flip"
                    data-uk-icon="icon: user"
                  ></span>
                  <input
                    name="username"
                    className="uk-input uk-border-pill"
                    placeholder="Username"
                    type="text"
                    ref={register}
                  />
                </div>
                {errors.username && (
                  <p className="validation">{errors.username.message}</p>
                )}
              </div>
              <div className="uk-margin-small">
                <div className="uk-inline uk-width-1-1">
                  <span
                    className="uk-form-icon uk-form-icon-flip"
                    data-uk-icon="icon: mail"
                  ></span>
                  <input
                    name="email"
                    className="uk-input uk-border-pill"
                    placeholder="Email"
                    type="text"
                    ref={register}
                  />
                </div>
                {errors.email && (
                  <p className="validation">{errors.email.message}</p>
                )}
              </div>
              <div className="uk-margin-small">
                <div className="uk-inline uk-width-1-1">
                  <span
                    className="uk-form-icon uk-form-icon-flip"
                    data-uk-icon="icon: lock"
                  ></span>
                  <input
                    name="password"
                    className="uk-input uk-border-pill"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    ref={register}
                  />
                </div>
                {errors.password && (
                  <p className="validation">{errors.password.message}</p>
                )}
              </div>
              <div className="uk-margin-small">
                <div className="uk-inline uk-width-1-1">
                  <span
                    className="uk-form-icon uk-form-icon-flip"
                    data-uk-icon="icon: lock"
                  ></span>
                  <input
                    name="cpassword"
                    className="uk-input uk-border-pill"
                    placeholder="Confirm Password"
                    type="password"
                    autoComplete="new-password"
                    ref={register}
                  />
                </div>
                {errors.cpassword && (
                  <p className="validation">{errors.cpassword.message}</p>
                )}
              </div>
              <div className="uk-margin-bottom">
                <button
                  type="submit"
                  className="uk-button uk-button-primary uk-border-pill uk-width-1-1"
                >
                  SIGN UP
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </>
  );
}

export default withRouter(Register);
