import gql from "graphql-tag";

const ENDPOINT_QUERY = gql`
  query Endpoints($serviceId: String!) {
    getEndpoints(serviceId: $serviceId) {
      id
      name
      description
      category
      route
      method
      tokenCost
      costUnit
      Header {
        key
        sample
        required
      }
      Query {
        key
        sample
        required
      }
      Request {
        sample
        descriptor
      }
      Response {
        sample
        descriptor
      }
    }
  }
`;

export default ENDPOINT_QUERY;
