import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useLazyQuery } from "@apollo/react-hooks";
import Query from "../../components/Query";
import { copyToClipboard } from "../../utils/copy";

import ENDPOINT_QUERY from "../../queries/api/endpoints";
import SERVICE_QUERY from "../../queries/api/service";
import SUBSCRIPTION_QUERY from "../../queries/subscription/subscription";

const Documentation = (props) => {
  const { serviceId } = props.match.params;
  const [endpoints, setEndpoints] = useState([]);
  const [activeEndpoint, setActiveEndpoint] = useState(null);
  const [inputSnapiKey, setInputSnapiKey] = useState();
  const [defaultSnapiKey, setDefaultSnapiKey] = useState(
    "<your-snapi-access-token>"
  );

  const [getEndpoints, { data }] = useLazyQuery(ENDPOINT_QUERY, {
    onCompleted: ({ getEndpoints }) => {
      setEndpoints(getEndpoints);
      setActiveEndpoint(getEndpoints[0]);
    },
  });

  const [getSubscription, { data: subscription }] = useLazyQuery(
    SUBSCRIPTION_QUERY,
    {
      onCompleted: ({ subscriptions }) => {
        setDefaultSnapiKey(subscriptions[0].snapi_key);
      },
    }
  );

  useEffect(() => {
    getEndpoints({ variables: { serviceId } });
    let result = JSON.parse(localStorage.getItem("user"));
    if (result) {
      const { user } = result;
      getSubscription({ variables: { user: user.id } });
    }
  }, [serviceId]);

  const buildCodeSnippet = (type) => {
    const snippet = [];
    if (type === "curl") {
      snippet.push(`curl --request ${activeEndpoint.method} \\`);

      if (activeEndpoint.method === "POST") {
        snippet.push(
          `  --url https://api.snapi.tech/api/${serviceId}/${activeEndpoint.route} \\`
        );
        snippet.push("  --header 'content-type: application/json' \\");
      } else {
        let code = `  --url https://api.snapi.tech/api/${serviceId}/${activeEndpoint.route}`;
        if (activeEndpoint.Query) {
          let queries = [];
          for (let query of activeEndpoint.Query) {
            queries.push(`${query.key}=${query.sample}`);
          }

          code = `${code}?${queries.join("&")}`;
        }
        snippet.push(`${code} \\`);
      }

      let snapi_header = `  --header 'snapi-key: ${
        inputSnapiKey ? inputSnapiKey : defaultSnapiKey
      }'`;
      snippet.push(`${snapi_header} ${activeEndpoint === "POST" ? "\\" : ""}`);

      if (activeEndpoint.Request) {
        snippet.push("  --data '{");

        const values = [];
        let entries = Object.entries(activeEndpoint.Request.sample);
        for (let [key, value] of entries) {
          values.push(
            `    "${key}": ${typeof value === "string" ? `"${value}"` : value}`
          );
        }
        snippet.push(values.join(",\n"));
        snippet.push("  }'");
      }
    }
    return snippet.join("\n");
  };

  const handleKeyChange = (evt) => {
    setInputSnapiKey(evt.target.value);
  };

  const handleEndpointChange = (name) => {
    const newActive = endpoints.find((endpoint) => endpoint.name === name);
    setActiveEndpoint(newActive);
  };

  return (
    <section className="uk-section uk-section-small uk-section-default uk-padding-remove-bottom">
      <div
        className="documentation uk-grid-collapse"
        data-uk-grid
        data-uk-height-viewport="expand: true; offset-top: true"
      >
        <div className="endpoints uk-width-medium">
          {/* <div className="documentation-header">
					<span>Endpoints</span>
				</div> */}
          <ul className="uk-list endpoint-list">
            {endpoints.map(({ name, category, method }) => (
              <li
                className={activeEndpoint.name === name ? "active" : ""}
                onClick={() => handleEndpointChange(name)}
              >
                <span
                  className={`uk-label uk-label-${method.toLowerCase()} uk-margin-small-right`}
                >
                  {method}
                </span>
                <span className="endpoint-selection-name uk-margin-small-right">
                  {name}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="uk-width-expand">
          <Query query={SERVICE_QUERY} variables={{ serviceId }}>
            {({ data: { services } }) => (
              <div className="service-details uk-width-expand">
                <div className="uk-container uk-container-expand uk-flex uk-flex-column">
                  <span className="service-title">{services[0].name}</span>
                  <span className="service-description">
                    {services[0].description}
                  </span>
                </div>
              </div>
            )}
          </Query>
          <div className="documentation-main uk-flex">
            <div className="uk-width-expand uk-padding-remove">
              {endpoints.length > 0 && (
                <>
                  <div className="documentation-header">
                    <span
                      className={`uk-label uk-label-${activeEndpoint.method.toLowerCase()} uk-margin-small-right`}
                    >
                      {activeEndpoint.method}
                    </span>
                    <span className="endpoint-name">
                      {activeEndpoint.name}{" "}
                      <code>
                        https://api.snapi.tech/api/{serviceId}/
                        {activeEndpoint.route}
                      </code>
                    </span>
                    {activeEndpoint.tokenCost !== 0 ? (
                      <span className="endpoint-token-cost uk-margin-auto-left uk-label uk-label-warning">
                        {activeEndpoint.tokenCost} Token
                        {activeEndpoint.tokenCost > 1 ? "s" : ""} per{" "}
                        {activeEndpoint.costUnit
                          ? activeEndpoint.costUnit
                          : "request"}
                      </span>
                    ) : (
                      <span className="endpoint-token-cost uk-margin-auto-left uk-label">
                        FREE
                      </span>
                    )}
                  </div>
                  <div className="endpoint-container">
                    <div className="endpoint-description uk-padding-remove-bottom">
                      <span className="endpoint-content-header">
                        Description
                      </span>
                      <p>{activeEndpoint.description}</p>
                    </div>
                    <div className="endpoint-usage">
                      <ul uk-accordion="multiple: true;">
                        <li className="uk-open">
                          <a className="uk-accordion-title" href="#">
                            Headers
                          </a>
                          <div className="uk-accordion-content">
                            <div className="uk-form-horizontal">
                              <div className="uk-margin">
                                <label className="uk-form-label">
                                  snapi-key
                                </label>
                                <div className="uk-form-controls">
                                  <input
                                    className="uk-input"
                                    type="text"
                                    placeholder="your-snapi-access-token"
                                    value={inputSnapiKey || defaultSnapiKey}
                                    onChange={handleKeyChange}
                                  />
                                  <span className="required">REQUIRED</span>
                                </div>
                              </div>
                              {activeEndpoint.Header.map((header) => (
                                <div className="uk-margin">
                                  <label className="uk-form-label">
                                    {header.key}
                                  </label>
                                  <div className="uk-form-controls">
                                    <input
                                      className="uk-input"
                                      type="text"
                                      value={header.sample}
                                      placeholder="some id"
                                    />
                                    {header.required && (
                                      <span className="required">REQUIRED</span>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </li>
                        {activeEndpoint.Query.length > 0 && (
                          <li className="uk-open">
                            <a className="uk-accordion-title" href="#">
                              Query Parameters
                            </a>
                            <div className="uk-accordion-content">
                              <div className="uk-form-horizontal">
                                {activeEndpoint.Query.map((query) => (
                                  <div className="uk-margin">
                                    <label className="uk-form-label">
                                      {query.key}
                                    </label>
                                    <div className="uk-form-controls">
                                      <input
                                        className="uk-input"
                                        type="text"
                                        value={query.sample}
                                        placeholder="some id"
                                      />
                                      {query.required && (
                                        <span className="required">
                                          REQUIRED
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </li>
                        )}
                        {activeEndpoint.Request && (
                          <li className="uk-open">
                            <a className="uk-accordion-title" href="#">
                              Request Body
                            </a>
                            <div
                              className="uk-accordion-content uk-grid-column-large"
                              data-uk-grid
                            >
                              <div className="uk-width-1-2">
                                <div className="endpoint-request-parameters ">
                                  <div className="endpoint-request-content-header">
                                    <span>Parameters</span>
                                  </div>
                                  {Object.entries(
                                    activeEndpoint.Request.descriptor
                                  ).map(([key, value]) => (
                                    <div
                                      className="endpoint-parameter"
                                      key={key}
                                    >
                                      <span className="endpoint-parameter-key">
                                        {key}
                                        <span className="endpoint-parameter-type">
                                          <span
                                            className={
                                              value.required ? "required" : ""
                                            }
                                          >
                                            {!value.required
                                              ? "optional"
                                              : "required"}
                                          </span>{" "}
                                          {value.type}
                                        </span>
                                      </span>
                                      {value.type === "enum" && (
                                        <span className="endpoint-parameter-enum">
                                          Possible values are{" "}
                                          {value.values.map((item, index) => (
                                            <>
                                              <code>{item}</code>
                                              {index !==
                                                value.values.length - 1 && ", "}
                                            </>
                                          ))}
                                        </span>
                                      )}
                                      <span className="endpoint-parameter-description">
                                        <ReactMarkdown
                                          source={value.description}
                                        />
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="uk-width-1-2">
                                <div className="endpoint-request-sample">
                                  <div className="endpoint-sample-header">
                                    <span className="uk-flex uk-flex-middle">
                                      Sample Request Body
                                      <i
                                        className="uk-margin-small-left code-copy"
                                        uk-icon="icon: fa-solid-clipboard; ratio: 0.8"
                                        uk-tooltip="Copy to clipboard"
                                        onClick={() => {
                                          copyToClipboard(
                                            JSON.stringify(
                                              activeEndpoint.Request.sample,
                                              0,
                                              2
                                            )
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                  <SyntaxHighlighter
                                    language="json"
                                    style={docco}
                                    wrapLines={true}
                                    customStyle={{ padding: 20 }}
                                  >
                                    {JSON.stringify(
                                      activeEndpoint.Request.sample,
                                      0,
                                      2
                                    )}
                                  </SyntaxHighlighter>
                                </div>
                              </div>
                            </div>
                          </li>
                        )}
                        {activeEndpoint.Response && (
                          <li className="uk-open">
                            <a className="uk-accordion-title" href="#">
                              Response
                            </a>
                            <div
                              className="uk-accordion-content uk-grid-column-large"
                              data-uk-grid
                            >
                              <div className="uk-width-1-2">
                                <div className="endpoint-response-parameters">
                                  <div className="endpoint-request-content-header">
                                    <span>Returns</span>
                                  </div>
                                  {Object.entries(
                                    activeEndpoint.Response.descriptor
                                  ).map(([key, value]) => (
                                    <div
                                      className="endpoint-parameter"
                                      key={key}
                                    >
                                      <span className="endpoint-parameter-key">
                                        {key}
                                        <span className="endpoint-parameter-type">
                                          {value.type}
                                        </span>
                                      </span>
                                      {value.type === "enum" && (
                                        <span className="endpoint-parameter-enum">
                                          Possible values are{" "}
                                          {value.values.map((item, index) => (
                                            <>
                                              <code>{item}</code>
                                              {index !==
                                                value.values.length - 1 && ", "}
                                            </>
                                          ))}
                                        </span>
                                      )}
                                      <span className="endpoint-parameter-description">
                                        <ReactMarkdown
                                          source={value.description}
                                        />
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="uk-width-1-2">
                                <div className="endpoint-response-sample">
                                  <div className="endpoint-sample-header">
                                    <span className="uk-flex uk-flex-middle">
                                      Sample Response
                                      <i
                                        className="uk-margin-small-left code-copy"
                                        uk-icon="icon: fa-solid-clipboard; ratio: 0.8"
                                        uk-tooltip="Copy to clipboard"
                                        onClick={() => {
                                          copyToClipboard(
                                            JSON.stringify(
                                              activeEndpoint.Response.sample,
                                              0,
                                              2
                                            )
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                  <SyntaxHighlighter
                                    language="json"
                                    style={docco}
                                    wrapLines={true}
                                    customStyle={{ padding: 20 }}
                                  >
                                    {JSON.stringify(
                                      activeEndpoint.Response.sample,
                                      0,
                                      2
                                    )}
                                  </SyntaxHighlighter>
                                </div>
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="uk-width-auto">
              {activeEndpoint && (
                <div className="uk-padding-remove">
                  <div className="documentation-header">
                    <span className="uk-flex uk-flex-middle">
                      Code Snippets
                      <i
                        className="uk-margin-small-left code-copy"
                        uk-icon="icon: fa-solid-clipboard; ratio: 0.8"
                        uk-tooltip="Copy to clipboard"
                        onClick={() => {
                          copyToClipboard(buildCodeSnippet("curl"));
                        }}
                      />
                    </span>
                  </div>
                  <div className="endpoint-code-snippet">
                    <SyntaxHighlighter
                      style={docco}
                      showLineNumbers={true}
                      customStyle={{ padding: 20 }}
                    >
                      {buildCodeSnippet("curl")}
                    </SyntaxHighlighter>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Documentation;
