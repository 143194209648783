import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/react-hooks";
import { withRouter, Link, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import Swal from "sweetalert2";

import LOGIN_MUTATION from "../../mutations/auth/login";

const LoginSchema = yup.object().shape({
  username: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
});

function Login(props) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { handleSubmit, register, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(LoginSchema),
  });

  const [login] = useMutation(LOGIN_MUTATION, {
    onCompleted: (data) => {
      Swal.close();
      localStorage.setItem("user", JSON.stringify(data.login));
      const redirect = `/${params.get("redirect") || ""}`;
      props.history.push(redirect);
    },
    onError: ({ graphQLErrors }) => {
      const {
        extensions: {
          exception: {
            data: { message },
          },
        },
      } = graphQLErrors[0];

      Swal.fire({
        icon: "error",
        title: "Unable to sign in",
        text: message[0].messages[0].message,
      });
    },
  });

  const onSubmit = (values) => {
    const { username, password } = values;
    Swal.fire({
      title: "Signing In...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
      onOpen: () => {
        login({ variables: { input: { identifier: username, password } } });
      },
    });
  };

  return (
    <>
      <div>
        <div className="uk-position-medium uk-position-left">
          <Link
            className="uk-icon-button uk-button-primary"
            uk-icon="icon: fa-solid-home; ratio: 0.8"
            to="/"
          ></Link>
        </div>
        <div className="uk-position-medium uk-position-right">
          <Link className="uk-button uk-button-primary" to="/sign-up">
            Sign Up
          </Link>
        </div>
      </div>
      <div
        className="login-bg uk-width-1-1 uk-flex uk-flex-center uk-flex-middle"
        uk-height-viewport="expand:true"
      >
        <div className="uk-width-medium uk-padding-small">
          <div className="uk-flex uk-flex-middle uk-flex-center uk-margin-bottom auth-header">
            <img className="uk-margin-small-right" src="/logo.png" alt="" />
            <h3 className="uk-text-center uk-margin-remove">SNAPI</h3>
          </div>
          <form className="toggle-className" onSubmit={handleSubmit(onSubmit)}>
            <fieldset className="uk-fieldset">
              <div className="uk-margin-small">
                <div className="uk-inline uk-width-1-1">
                  <span
                    className="uk-form-icon uk-form-icon-flip"
                    data-uk-icon="icon: user"
                  ></span>
                  <input
                    name="username"
                    className="uk-input uk-border-pill"
                    placeholder="Username"
                    type="text"
                    ref={register}
                  />
                </div>
                {errors.username && (
                  <p className="validation">{errors.username.message}</p>
                )}
              </div>
              <div className="uk-margin-small">
                <div className="uk-inline uk-width-1-1">
                  <span
                    className="uk-form-icon uk-form-icon-flip"
                    data-uk-icon="icon: lock"
                  ></span>
                  <input
                    name="password"
                    className="uk-input uk-border-pill"
                    placeholder="Password"
                    type="password"
                    ref={register}
                  />
                </div>
                {errors.password && (
                  <p className="validation">{errors.password.message}</p>
                )}
              </div>
              {/* <div className="uk-margin-small">
                <label>
                  <input
                    className="uk-checkbox uk-margin-small-right"
                    type="checkbox"
                  />
                  Keep me logged in
                </label>
              </div> */}
              <div className="uk-margin-bottom">
                <button
                  type="submit"
                  className="uk-button uk-button-primary uk-border-pill uk-width-1-1"
                >
                  SIGN IN
                </button>
              </div>
            </fieldset>
          </form>
          <form className="toggle-className" hidden>
            <div className="uk-margin-small">
              <div className="uk-inline uk-width-1-1">
                <span
                  className="uk-form-icon uk-form-icon-flip"
                  data-uk-icon="icon: mail"
                ></span>
                <input
                  className="uk-input uk-border-pill"
                  placeholder="E-mail"
                  required
                  type="text"
                />
              </div>
            </div>
            <div className="uk-margin-bottom">
              <button
                type="submit"
                className="uk-button uk-button-primary uk-border-pill uk-width-1-1"
              >
                Recover Password
              </button>
            </div>
          </form>
          <div>
            <div className="uk-text-center">
              <a
                className="uk-link-reset uk-text-small toggle-className"
                data-uk-toggle="target: .toggle-className ;animation: uk-animation-fade"
              >
                Forgot your password?
              </a>
              <a
                className="uk-link-reset uk-text-small toggle-className"
                data-uk-toggle="target: .toggle-className ;animation: uk-animation-fade"
                hidden
              >
                <span data-uk-icon="arrow-left"></span> Back to Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Login);
