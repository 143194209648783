import React from "react";
import { Link } from "react-router-dom";
import Query from "../../components/Query";

import API_LIST_QUERY from "../../queries/api/apilist";
import CATEGORIES_QUERY from "../../queries/category/categories";

function Home() {
  return (
    <section className="uk-section uk-section-small uk-section-default uk-padding-remove-bottom">
      <div className="uk-container uk-container-expand uk-margin-top uk-margin-large-bottom">
        <ul className="uk-subnav uk-subnav-pill">
          <li className="uk-active" data-uk-filter-control="">
            <a href="#">Show All</a>
          </li>
          <Query query={CATEGORIES_QUERY}>
            {({ data: { categories } }) => {
              return categories.map(({ name }) => (
                <li
                  key={name}
                  className={`.${name.toLowerCase()}-card`}
                  data-uk-filter-control={`.${name.toLowerCase()}-card`}
                >
                  <a href="#">{name}</a>
                </li>
              ));
            }}
          </Query>
        </ul>
        <div
          className="uk-grid uk-grid-medium uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl uk-grid-match js-filter"
          data-uk-grid="masonry: true"
        >
          <Query query={API_LIST_QUERY}>
            {({ data: { services } }) => {
              return services.map(
                ({
                  name,
                  description,
                  serviceId,
                  averageLatency,
                  tags,
                  category,
                  provider,
                }) => (
                  <div
                    key={serviceId}
                    className={`${category.name.toLowerCase()}-card`}
                    data-tags={tags}
                  >
                    <div className="uk-card uk-card-small uk-card-default">
                      <div className="uk-card-header">
                        <div
                          className="uk-grid uk-grid-small uk-text-small"
                          data-uk-grid
                        >
                          <div className="uk-width-expand">
                            <span className="cat-txt">{category.name}</span>
                          </div>
                          {/* <div class="uk-width-auto uk-text-right uk-text-muted uk-flex uk-flex-middle">
														<span
															className="uk-margin-small-right"
															data-uk-tooltip="title: Average Response Time"
															data-uk-icon="icon:clock; ratio: 0.8"
														></span>
														{averageLatency}ms
													</div> */}
                        </div>
                      </div>
                      <div className="uk-card-body">
                        <h6 className="uk-margin-small-bottom uk-margin-remove-adjacent uk-text-bold">
                          {name}
                        </h6>
                        <p className="uk-text-small uk-text-muted">
                          {description}
                        </p>
                      </div>
                      <div className="uk-card-footer">
                        <div
                          className="uk-grid uk-grid-small uk-grid-divider uk-flex uk-flex-middle"
                          data-uk-grid
                        >
                          <div className="uk-width-expand uk-text-small">
                            {provider.name}
                          </div>
                          <div className="uk-width-auto uk-text-right">
                            <Link
                              data-uk-tooltip="title: View Documentation"
                              to={`/documentation/${serviceId}`}
                              className="uk-icon-link"
                              data-uk-icon="icon:fa-solid-info-circle; ratio: 1"
                            ></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            }}
          </Query>
        </div>
      </div>
    </section>
  );
}

export default Home;
