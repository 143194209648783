import gql from "graphql-tag";

const SERVICE_QUERY = gql`
  query Service($serviceId: String!) {
    services(where: { serviceId: $serviceId }) {
      id
      name
      description
    }
  }
`;

export default SERVICE_QUERY;
