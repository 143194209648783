import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import UIkit from "uikit";
import DECRYPT_QUERY from "../../queries/token/decrypt";
import SUBSCRIPTION_QUERY from "../../queries/subscription/subscription";

const Nav = (props) => {
  useEffect(() => {
    let searchTerm;
    let search = UIkit.util.$(".search-fld");
    let searchVal = UIkit.util.$(".search-filter");
    let filterBtn = UIkit.util.$$("li[data-uk-filter-control] a");
    let formEl = UIkit.util.$("#search-form");
    let debounce;

    UIkit.util.on(search, "keyup", function () {
      clearTimeout(debounce);
      debounce = setTimeout(function () {
        var value = search.value;
        var finalValue = value.toLowerCase();
        var searchTerm = "";

        if (value.length) searchTerm = '[data-tags*="' + finalValue + '"]';
        UIkit.util.attr(searchVal, "data-uk-filter-control", searchTerm);
        searchVal.click();
      }, 300);
    });

    // prevent send form on press enter
    UIkit.util.on(formEl, "keypress", function (e) {
      var key = e.charCode || e.keyCode || 0;
      if (key == 13) {
        e.preventDefault();
        console.log("Prevent submit on press enter");
      }
    });

    // empty field and attribute on click filter button
    UIkit.util.on(filterBtn, "click", function () {
      var inputVal = search.value;
      if (inputVal.length) {
        // empty field
        search.value = "";
        searchTerm = '[data-tags*=""]';
        // empty attribute
        UIkit.util.attr(searchVal, "data-uk-filter-control", searchTerm);
        console.log("empty field and attribute");
      }
    });
  }, []);

  const { pathname } = useLocation();
  const [user, setUser] = useState();
  const [tokens, setTokens] = useState(0);

  const [verifyJWT, { data }] = useLazyQuery(DECRYPT_QUERY, {
    onCompleted: ({ decryptToken }) => {
      const { exp, statusCode } = decryptToken;
      if (exp < Date.now() / 1000 || statusCode === 400) {
        localStorage.removeItem("user");
        setUser(undefined);
      } else {
        let result = JSON.parse(localStorage.getItem("user"));
        if (result) {
          const { user } = result;
          setUser(user);
          getSubscription({ variables: { user: user.id } });
        }
      }
    },
    onError: (error) => {
      console.log(error);
      localStorage.removeItem("user");
      setUser(undefined);
    },
  });

  const [getSubscription, { data: subscriptions }] = useLazyQuery(
    SUBSCRIPTION_QUERY,
    {
      onCompleted: ({ subscriptions }) => {
        setTokens(subscriptions[0].tokens);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    let result = JSON.parse(localStorage.getItem("user"));
    if (result) {
      verifyJWT({ variables: { jwt: result.jwt } });
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  if (props.hasNav)
    return (
      <>
        <header id="site-head">
          <div className="uk-container uk-container-expand">
            <div
              className="uk-grid uk-grid-medium uk-flex uk-flex-middle"
              data-uk-grid
            >
              <div className="uk-width-auto">
                <a href="/" className="uk-logo" title="">
                  <img src="/logo.png" alt="" />
                </a>
              </div>
              <div className="uk-width-expand">
                {pathname === "/" && (
                  <form id="search-form" action="">
                    <div className="uk-inline uk-width-1-1">
                      <span
                        className="uk-form-icon uk-form-icon-flip"
                        data-uk-icon="icon: search"
                      ></span>
                      <input
                        className="uk-input uk-width-1-1 search-fld"
                        type="text"
                        placeholder="Type your search"
                        autofocus
                      />
                      <a
                        href="#"
                        className="search-filter"
                        data-uk-filter-control="[data-tags*='']"
                        hidden
                      >
                        Search
                      </a>
                    </div>
                  </form>
                )}
              </div>
              <div className="uk-width-auto">
                <ul className="uk-subnav uk-flex uk-flex-middle" data-uk-margin>
                  <li className="uk-visible@s">
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  {!user ? (
                    <>
                      <li className="uk-visible@s">
                        <Link to="/sign-in">Sign In</Link>
                      </li>
                      <li className="uk-visible@s">
                        <Link className="sign-up" to="/sign-up">
                          Sign Up
                        </Link>
                      </li>
                    </>
                  ) : (
                    <li className="uk-visible@s">
                      <button className="user-menu">
                        <i uk-icon="icon: fa-regular-user-circle; ratio: 1.3" />
                        <span className="uk-text-bold uk-margin-small-left uk-margin-small-right uk-flex uk-flex-column">
                          <span>{user.username}</span>
                          <small>Tokens: {tokens}</small>
                        </span>
                        <i uk-icon="icon: fa-solid-caret-down" />
                      </button>
                      <div data-uk-dropdown="pos: bottom-center">
                        <ul className="uk-nav uk-dropdown-nav">
                          <li>
                            <Link to="/usage">
                              <span className="uk-flex uk-flex-middle">
                                <i uk-icon="icon: fa-solid-list-alt; ratio: 0.8" />
                                <span className="uk-margin-small-left">
                                  Token Usage
                                </span>
                              </span>
                            </Link>
                          </li>
                          <li className="uk-margin-small-top">
                            <Link to="/history">
                              <span className="uk-flex uk-flex-middle">
                                <i uk-icon="icon: fa-solid-shopping-bag; ratio: 0.8" />
                                <span className="uk-margin-small-left">
                                  Purchase History
                                </span>
                              </span>
                            </Link>
                          </li>
                          <li className="uk-margin-small-top">
                            <a href="#">
                              <span className="uk-flex uk-flex-middle">
                                <i uk-icon="icon: fa-solid-key; ratio: 0.8" />
                                <span className="uk-margin-small-left">
                                  Change Password
                                </span>
                              </span>
                            </a>
                          </li>
                          <li className="uk-margin-small-top">
                            <Link to="" onClick={() => logout()}>
                              <span className="uk-flex uk-flex-middle">
                                <i uk-icon="icon: fa-solid-sign-out-alt; ratio: 0.8" />
                                <span className="uk-margin-small-left">
                                  Logout
                                </span>
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </header>
        <div className="spacer"></div>
      </>
    );
  else return null;
};

export default Nav;
