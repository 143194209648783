import gql from "graphql-tag";

const REGISTER_MUTATION = gql`
  mutation Register($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      user {
        id
        username
        email
      }
    }
  }
`;

export default REGISTER_MUTATION;
