import React from "react";
import Query from "../../components/Query";
import PACKAGES_QUERY from "../../queries/package/packages";
import axios from "axios";

import Swal from "sweetalert2";

const Pricing = (props) => {
  const purchaseTokens = async (packageId) => {
    const result = JSON.parse(localStorage.getItem("user"));
    if (result) {
      Swal.fire({
        title: "Redirecting to Payment Page",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onOpen: async () => {
          const { data: paymentLink } = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/subscriptions/purchaseToken`,
            { packageId },
            {
              headers: {
                Authorization: `Bearer ${result.jwt}`,
              },
            }
          );
          console.log(paymentLink);
          window.location.href = paymentLink;
        },
      });
    } else {
      props.history.push("/sign-in?redirect=pricing");
    }
  };

  return (
    <section className="uk-section uk-section-small uk-section-default uk-padding-remove-bottom">
      <div className="uk-container uk-margin-top">
        <h3>SNAPI Token Purchase</h3>
        <div className="uk-card uk-card-pricing">
          <div className="uk-card-body uk-padding-small">
            <table className="uk-table uk-table-striped uk-table-divider">
              <thead>
                <tr>
                  <th>Package</th>
                  <th>Tokens</th>
                  <th>Package Price</th>
                  <th className="uk-width-small"></th>
                </tr>
              </thead>
              <tbody>
                <Query query={PACKAGES_QUERY}>
                  {({ data: { packages } }) => {
                    return packages.map(({ id, name, tokens, price }) => (
                      <tr key={id}>
                        <td>{name}</td>
                        <td>{tokens.toLocaleString()}</td>
                        <td>${price}</td>
                        <td>
                          <button
                            className="uk-button uk-button-primary uk-button-small"
                            onClick={() => purchaseTokens(id)}
                          >
                            Purchase
                          </button>
                        </td>
                      </tr>
                    ));
                  }}
                </Query>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
